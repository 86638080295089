// This component is responsible for rendering the Choose Layout bilings
// The client can choose the layout of the bilings, the color of the bilings and add a logo to the bilings.
// The component also displays a preview of the bilings.
// Author: Vitor de Jesus

// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=2035-5475&t=vGcPbn62U5gjfpZ4-4

import React, { useEffect } from 'react';

import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';
import { Grid, Box } from '@mui/material';

import Modelo from '../../assets/images/Modelo01.png';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';
import { set } from 'date-fns';

const ChooseLayout  = ({ templates ,setColorCharge, setLayoutCharge, setLogoCharge, NextPage, errorLogo }) => {

    const [selected, setSelected] = React.useState('Padrão');
    const [selectedColor, setSelectedColor] = React.useState('#7B25FF');
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [errorImage, setErrorImage] = React.useState(false);

    const [colors, setColors] = React.useState([
        '#82BD69', '#1837D7', '#DEA73F', '#BB0404', '#7B25FF', '#ed15be'
    ]);

    useEffect(() => {
        setColorCharge(selectedColor);
        setLayoutCharge(0);
        setLogoCharge('');
    }, []);


    const handleColorChange = (color) => {
        setColorCharge(color);
        setSelectedColor(color);
    };

    const handleFileChange = (files) => {
        if (files.length > 0) {
            if (files[0].type.includes('image')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const newFile = {
                        file: {
                            name: files[0].name,
                            type: files[0].type,
                        },
                        base64String: reader.result 
                    };
                    setSelectedFiles([newFile]); 
                    setErrorImage(false);
                    setLogoCharge(reader.result);
                };
                reader.readAsDataURL(files[0]); 
            } else {
                setErrorImage(true);
            }
        }
    };

    const handleClick = (button) => {
        console.log('index', button.index);
        setLayoutCharge(button.index);
        setSelected(button.title);
    }

    const deleteFile = () => {
        setSelectedFiles([]);
        setLogoCharge('');
    };

    const HelpAdd = (Number, desc) => (
        <Box style={{ marginBottom: '10px' }}>
            <Grid container spacing={0}>
                <Grid item xs={12} style={{ background: '#FFF', padding: '10px', borderRadius: '12px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div style={{
                            background: ThemeColors.purple200, color: ThemeColors.purple600,
                            padding: '5px', borderRadius: '6px', minWidth: '40px', minHeight: '40px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                            fontWeight: 'bold', fontSize: '16px', marginRight: '10px', fontFamily: 'Satoshi-Black'
                        }}>
                            {Number}
                        </div>
                        <Titles type={"P1"}>{desc}</Titles>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <>
            <HeaderPage title="Adicionar Planilhas" />
            <Grid container spacing={4} sx={{ padding: 5 }}>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    {HelpAdd(1, "Qual será o modelo de layout da sua fatura?")}
                    <Box item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                        {templates.map((template, index) => (
                            <Button
                                key={template.index} 
                                onClick={() => index === 0 && handleClick(template)}  
                                sx={{
                                    flexGrow: 1,
                                    mx: index === 0 || index === templates.length - 1 ? 0 : 0.5,
                                    bgcolor: selected === template.title ? '#8142FC' : (index === 0 ? ThemeColors.gray30 : 'lightgray'),  
                                    color: '#fff',
                                    borderRadius: '15px',
                                    '&:hover': { bgcolor: selected === template.title ? '#8142FC' : 'gray' },
                                    cursor: index === 0 ? 'pointer' : 'default', 
                                    pointerEvents: index === 0 ? 'auto' : 'none'  
                                }}
                                disabled={index !== 0} 
                            >
                                {template.title} 
                            </Button>
                        ))}
                    </Box>
                    {HelpAdd(2, "Agora me conta: qual será a cor das suas faturas?")}
                    <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                        {colors.map((color) => (
                            <Grid item key={color}>
                                <Box
                                    sx={{
                                        width: '50px', height: '50px', background: color,
                                        opacity: selectedColor === color ? 1 : 0.2, borderRadius: '12px',
                                        cursor: 'pointer', marginBottom: '10px', marginLeft: '10px'
                                    }}
                                    onClick={() => handleColorChange(color)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {HelpAdd(3, "Insira o logotipo da sua empresa na fatura.")}
                    <Grid item xs={12}>
                        <Dropzone
                            onDrop={handleFileChange}
                            accept="image/*"
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    style={{
                                        cursor: selectedFiles.length === 0 ? 'pointer' : 'default',
                                        textAlign: 'center',
                                        background: errorImage ? ThemeColors.red01 : errorLogo ? ThemeColors.red01 : '#E5E5E5',
                                        marginTop: '10px',
                                        borderRadius: '4px',
                                        height: '170px'
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                                        {selectedFiles.length === 0 && (
                                            <>
                                                <CloudUploadIcon style={{ fontSize: '64px', color: errorImage ? ThemeColors.red03 : '#848484' }} />
                                                <Titles children={errorImage ? 'Adicione apenas imagens' : 'Clique para adicionar arquivo'} color={errorImage ? ThemeColors.red03 : '#848484'} type={'P1'} />
                                            </>
                                        )}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px', width: '90%' }}>
                                            {selectedFiles.map((file, index) => (
                                                <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'space-between'} minHeight={'48px'} sx={{ background: '#fff', borderRadius: '4px', padding: '0 10px', marginBottom: '10px', width: '100%' }}>
                                                    <Box display={'flex'} gap={'10px'} alignItems={'end'}>
                                                        <img src={file.base64String} alt="file preview" width={'24px'} height={'24px'} style={{ objectFit: 'cover' }} />
                                                        <p style={{ fontSize: '13px', color: '#303035' }}>
                                                            {file.file.name.length > 25 ? `${file.file.name.substring(0, 25)}...` : file.file.name}
                                                        </p>
                                                    </Box>
                                                    <Box onClick={deleteFile} sx={{ cursor: 'pointer', '&:hover': { scale: '1.1' }, transition: '.3s' }}>
                                                        <DeleteIcon style={{ color: '#FF3D3D' }} />
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </div>
                            )}
                        </Dropzone>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ padding: '15px', width: '250px', borderRadius: '6px' }}
                                onClick={() => NextPage()}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid item xs={12} md={12} style={{ background: '#FFF', borderRadius: '12px', padding: '20px', display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img
                            src={Modelo}
                            alt="Modelo"
                            style={{
                                maxHeight: '80%',
                                objectFit: 'contain'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ChooseLayout;