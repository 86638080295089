// This is a custom hook that returns the current step of the self register form
// Updated at: 16/09/2024

// React
import React, { useEffect, useState } from "react";

// Components and functions for the project
import Step1 from "./Steps/Step1.js";
import Step2 from "./Steps/Step2.js";
import Step3 from "./Steps/Step3.js";
import Step4 from "./Steps/Step4.js";
import Step5 from "./Steps/Step5.js";

const useSelfRegister = () => {
  // States for the function
  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState("");
  const [canMoveForward, setCanMoveForward] = useState(false);

  // Step 1
  const [selected, setSelected] = useState("");
  // Step 2
  const [username, setUsername] = useState("");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [contact, setContact] = useState("");
  // Step 3
  const [cep, setCep] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  // Step 4
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // Step 5
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentForm, setPaymentForm] = useState("");

  // Functions to change the step
  const nextStep = () => {
    if (canMoveForward) {
      setCurrentStep((prev) => (prev < 5 ? prev + 1 : 5));
      setMessage("");
    } else {
      setMessage("Por favor, preencha todos os campos.");
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : 1));
    setMessage("");
  };

  // Function to validade if the user can move forward in the multistep form
  const validateStep = (step) => {
    switch (step) {
      case 1:
        return selected !== "";
      case 2:
        return username !== "" && cpfOrCnpj !== "" && contact !== "";
      case 3:
        return cep !== "" && state !== "" && street !== "" && number !== "";
      case 4:
        return email !== "" && password !== "" && confirmPassword !== "" && password === confirmPassword;
      case 5: 
        return selected !== null && paymentForm !== null;
      default:
        return false;
    }
  };

  // Effect to check the validation of the current step
  useEffect(() => {
    setCanMoveForward(validateStep(currentStep));
  }, [currentStep, selected, username, cpfOrCnpj, contact, cep, state, street, number, email, password, confirmPassword])

  // Function to render the current Step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 message={message} selected={selected} setSelected={setSelected} />;
      case 2:
        return <Step2 message={message} selected={selected} contact={contact} cpf={cpfOrCnpj} setContact={setContact} setUsername={setUsername} setCpf={setCpfOrCnpj} username={username} />;
      case 3:
        return <Step3 message={message} cep={cep} number={number} setCep={setCep} setNumber={setNumber} state={state} setState={setState} city={city} setCity={setCity} setStreet={setStreet} street={street} />;
      case 4:
        return <Step4 message={message} email={email} setEmail={setEmail} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} password={password} setPassword={setPassword} setShowConfirmPassword={setShowConfirmPassword} showConfirmPassword={showConfirmPassword} setShowPassword={setShowPassword} showPassword={showPassword} />;
      case 5:
        return <Step5 message={message} selectedPlan={selectedPlan} paymentForm={paymentForm} setPaymentForm={setPaymentForm} setSelectedPlan={setSelectedPlan} />;
      default:
        return null;
    }
  };

  return { currentStep, nextStep, prevStep, renderCurrentStep, message };
};

export default useSelfRegister;