// Desc: Página principal de campanhas, onde é possível visualizar as campanhas ativas e desativadas.

// React Components and DOM Elements
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Loading from '../../components/Loading/Loading';
import Pause from '@mui/icons-material/Pause';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// Coponents and functions from the project
import { CampaignsByUuidAccount } from '../../api/backend/Campaigns/CampaignsByUuidAccount';
import DynamicTable from '../../components/Tables/DynamicTable';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import PlusIcon from '../../assets/Icons/components/Plus';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png";
import Titles from "../../components/Titles/Titles";
import Button from "@mui/material/Button";
import ModalR from '../../components/Modals/ModalR';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import { CampaignStatus } from '../../api/backend/Campaigns/CampaignStatus';
import { PauseCircleFilledOutlined, Visibility } from '@mui/icons-material';
import { CampaignHidden } from '../../api/backend/Campaigns/CampaignHidden';

function CampaignPage() {

  let Navigate = useNavigate();

  // Authentication Context Functions
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});

  // State variables
  const [dataFromAPI, setDataFromAPI] = useState([{
  }]);

  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);

  // Show loading screen for 3 seconds
  useEffect(() => {

    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
      fetchData();
    
  }, [userInfo])


  const alterVisibility = async (campaign_id, visibility) => {
    try {
      let data = { 'campaign_id': campaign_id, 'visibility': visibility };

      const campaignsData = await CampaignHidden(data);

      console.log(campaignsData, 'campaignsData');
      if (campaignsData.message === 'Visibility changed successfully') {

        fetchData();
      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);

    }
    finally {
      setOpenModalR(false);
    }
  }

  // Fetch data from API
  const fetchData = async () => {
    try {

   
      const resultJson = await CampaignsByUuidAccount();

      console.log('campaignsData2', resultJson);
      const data = resultJson.map((item) => {
        return {
          id: item[0],
          uuid: item[1],
          title: item[2],
          start_date: item[3],
          expiration_date: item[4],
          type: item[6],
          status: item[11] ? 'Em uso' : 'Desativado',
          icon: 'Campanha',
          desc: item[17],
          Visibility: item[22] ? 'Visível' : 'Oculto'

        };
      });

      
      setDataFromAPI(data);


    } catch (error) {
      console.error('Erro ao obter dados da API:', error);
    } finally {
      setLoading(false);
    }
  }

  const [optionSelected, setOptionSelected] = useState('');
  const [idCampaign, setIdCampaign] = useState(null);
  const [openModalR, setOpenModalR] = useState(false);

  const selectedOption = (option) => {
    console.log(option, 'option');
    if (option.status === "Desativado") {
      setOptionSelected('connect');
      setIdCampaign(option.id);
    } else {
      setOptionSelected('disconnect');
      setIdCampaign(option.id);
    }

    setOpenModalR(true);
  }

  const handleStatus = async (campaign_id, status) => {
    try {
      
      let data = {  'campaign_id': campaign_id, 'status': status };

      console.log(data, 'data');

      const campaignsData = await CampaignStatus(data);

      console.log(campaignsData, 'campaignsData');
      if (campaignsData.message === 'Status changed successfully') {
        fetchData();
      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);

    }
    finally {
      setOpenModalR(false);
    }
  }


  // Redirect to add campaign page
  const handleModalOpen = () => {
    Navigate('/campanhas/adicionar');
  };

  // Formatting date
  const formatarData = (data) => {
    const options = { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(data).toLocaleDateString('pt-BR', options);
  };


  // Show loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }


  return (
    <Fragment>
      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>
          <HeaderPage title="Campanhas" />

          {

            dataFromAPI && dataFromAPI.length > 0 && (
              <DynamicTable
                title="Campanhas"
                data={dataFromAPI}
                columns={[
                  { header: '', field: 'icon', render: (icon) => <StatusIcon status={icon} /> },
                  { header: 'Titulo', field: 'title' },
                  { header: 'Descrição', field: 'desc' },
                  { header: 'Inicio', field: 'start_date', render: (data) => formatarData(data) },
                 // { header: 'Fim', field: 'expiration_date', render: (data) => formatarData(data) },
                  { header: 'Tipo', field: 'type', render: (type) => <StatusLabel status={type} usingOnTable />, filter: true },
                  { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },
                ]}
                actions={[

                  // { icon: <EditIcon />, tooltip: 'Editar', isVisible: (item) => true },
                  // { icon: <DuplicateIcon />, tooltip: 'Duplicar', isVisible: (item) => true },
                  // { icon: <DeleteIcon />, tooltip: 'Deletar', isVisible: (item) => true },
                  { icon: <PauseCircleFilledOutlined />, tooltip: 'Desativar', action: (item) => selectedOption(item) , isVisible: (item) => item && item.status === 'Em uso' },
                  { icon: <PlayCircleOutlineIcon />, tooltip: 'Ativar', action: (item) => selectedOption(item), isVisible: (item) => item && item.status === 'Desativado' },
                  { icon: <Visibility />, tooltip: 'Ocultar', action: (item) => alterVisibility(item.id, false), isVisible: (item) => item && item.Visibility === 'Visível' },
                  { icon: <Visibility />, tooltip: 'Exibir', action: (item) => alterVisibility(item.id, true), isVisible: (item) => item && item.Visibility === 'Oculto' },
                ]}

                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Campanha ', action: () => handleModalOpen() }}

                activeSearch={true}
                titleSearch={'Pesquisar Campanha'}
                titleLabel={'Campanhas por página'}
              />

            ) || (

              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem campanhas :("} color={"#8033F4"} />
                    <Titles type={"P1"} children={"Crie uma agora mesmo, é fácil e rápido"} />

                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => handleModalOpen()}
                    >
                      Criar campanha
                    </Button>
                  </Box>
                </Box>
              </Box>

            )
          }
        </Box>
      </Fade>

      <ModalR
        cancelText={"Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={optionSelected === 'connect' ? "Ativar campanha" : "Desativar campanha"}
        subTitle={optionSelected === 'connect' ? "A voltará a ser enviada para os clientes" : "A campanha não será mais enviada para os clientes"}
        onClose={() => setOpenModalR(false)}
        isOpen={openModalR}
        onConfirm={() => handleStatus(idCampaign,optionSelected === 'connect')}
        title={optionSelected === 'connect' ? "Deseja ativar a campanha?" : "Deseja desativar a campanha?"}
        emoji={optionSelected === 'connect' ? 'emoji02' : 'emoji10'}
        iconColor={ThemeColors.purple400}
      />

    </Fragment>
  );
}
export default CampaignPage;
