// Desc: Page to add new sheets to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Select, MenuItem, Button, FormHelperText, Menu } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";

import { encrypt, decrypted } from '../../authMiddleware';
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { isAuthenticated } from "../../authMiddleware";
import { PostListagens } from "../../api/n8n/Listagens/PostListagens";
import { id } from "date-fns/locale";
import Loading from "../../components/Loading/Loading";

import { ApiAlls } from "../../api/n8n/Listagens/ApisAll";

// Render Form to add new sheets
function SheetsForm({ setDataR, copyRespostas, setCopyRespostas, setErrors, errors, setTabValue, setErroLoading, uuidClient }) {

  // State to authenticate control
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();


  // States to control the form fields
  const [formErros, setFormErros] = useState({});
  const [setFormateList, setListagensOptions] = useState([]);
  const [formateSelected, setFormateSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);

  const listOptions = [
    { id: 2, title: "Google planilhas" },
    { id: 3, title: "API" },
    //{ id: 3, title: "Arquivo XLS" },
  ];

  const [respostas, setRespostas] = useState({
    nameSheets: copyRespostas?.nameSheets || "",
    descriptionSheets: copyRespostas?.descriptionSheets || "",
    linkSheets: copyRespostas?.linkSheets || "",
    formatSheets: copyRespostas?.formatSheets || "",
    deleteDuplicatedVariables: copyRespostas?.deleteDuplicatedVariables || ""
  });

  useEffect(() => {
    setListagensOptions(listOptions);
    if (!authenticated) {
      navigate('/login');
    } else {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  const getApi = async () => {
    try {

      const response = await ApiAlls(uuidClient);

      console.log(response);
      const listagens = response.map((item) => {
        return {
          id: item.id,
          title: item.title,
          desc: item.description,
          url: item.url,
          method: item.apiMethod

        }

      });

      setApiData(listagens);

    } catch (error) {
      console.error("Erro ao obter dados da API:", error);

    }

  }

  useEffect(() => {
    getApi();
  }, []);


  async function CheckSheetsPermission() {

    setLoading(true);

    try {

      if (!respostas.nameSheets) formErros.nameSheets = "Campo obrigatório";
      if (!respostas.descriptionSheets) formErros.descriptionSheets = "Campo obrigatório";
      if (!respostas.linkSheets && respostas.formatSheets === "Google planilhas") formErros.linkSheets = "Campo obrigatório";
      if (!respostas.formatSheets) formErros.formatSheets = "Campo obrigatório";
      if (!respostas.deleteDuplicatedVariables) formErros.deleteDuplicatedVariables = "Campo obrigatório";

      if (Object.keys(formErros).length > 0) {
        setCopyRespostas(respostas);
        setErrors(formErros);
        return;
      }

      const response = await axios.post('https://api.123zap.com.br/webhook/CheckSheetsPermission', {
        uuid_account: userInfo['UUID'],
        full_token: userInfo['TOKEN'],
        listagem: respostas.linkSheets,
        type: respostas.formatSheets,
        api: respostas.api,
        id: userInfo['EMAIL']
      });

      const items = Object.entries(response.data["0"]["json"]).map(([field, value]) => ({
        field,
        value
      }));

      if (items.length > 0) {
        setDataR(items);
        setTabValue(1);
        setErroLoading(false);
      } else {
        setErroLoading(true);
        formErros.linkSheets = "Link inválido";
        setErrors(formErros);
      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);
      setLoading(false);
      setErroLoading(true);
      formErros.linkSheets = "Ocorreu um erro com o link";
      setErrors(formErros);
    } finally {
      setCopyRespostas(respostas);
      setErrors(formErros);
      setLoading(false);
    }

  }

  const handleInputChange = (name, value) => {
    setRespostas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px', 'padding': '20px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          variant="outlined"
          name="nameSheets"
          label="Digite um nome para identificar a planilha"
          value={respostas.nameSheets || ""}
          onChange={(e) => handleInputChange("nameSheets", e.target.value)}
          sx={{ background: errors.nameSheets ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={errors.nameSheets}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.nameSheets && <FormHelperText style={{ color: "red" }}>{errors.nameSheets}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="descriptionSheets"
          label="Digite uma descrição para a planilha"
          sx={{ background: errors.descriptionSheets ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={respostas.descriptionSheets || ""}
          onChange={(e) => handleInputChange("descriptionSheets", e.target.value)}
          error={errors.descriptionSheets}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.descriptionSheets && <FormHelperText style={{ color: "red" }}>{errors.descriptionSheets}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.formatSheets}>
        <InputLabel
          style={{ color: ThemeColors.gray50 }}
        >Você deseja deletar itens com variáveis duplicadas?</InputLabel>
        <Select
          name="deleteDuplicateVariables"
          label="Você deseja deletar itens com variáveis duplicadas?"
          value={respostas.deleteDuplicatedVariables || ""}
          onChange={(e) => {
            handleInputChange("deleteDuplicatedVariables", e.target.value);
            setFormateSelected(setFormateList.find((item) => item.title === e.target.value));
          }}
          sx={{ background: errors.deleteDuplicatedVariables ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          required
        >
          <MenuItem value={"Sim"}>
            Sim
          </MenuItem>
          <MenuItem value={"Não"}>
            Não
          </MenuItem>
        </Select>
        {!!errors.deleteDuplicatedVariables && <FormHelperText style={{ color: "red" }}>{errors.deleteDuplicatedVariables}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.formatSheets}>
        <InputLabel
          style={{ color: ThemeColors.gray50 }}
        >Em qual formato sua planilha está?</InputLabel>
        <Select
          name="formatSheets"
          label="Em qual formato sua planilha está?"
          value={respostas.formatSheets || ""}
          onChange={(e) => {
            handleInputChange("formatSheets", e.target.value);
            setFormateSelected(setFormateList.find((item) => item.title === e.target.value));
          }}
          sx={{ background: errors.formatSheets ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          required
        >
          {setFormateList.map((option) => (
            <MenuItem key={option.id} value={option.title}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        {!!errors.formatSheets && <FormHelperText style={{ color: "red" }}>{errors.formatSheets}</FormHelperText>}
      </FormControl>

      {respostas.formatSheets === "Google planilhas" && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            autoFocus
            name="linkSheets"
            label="Digite ou cole aqui o link da sua planilha:"
            sx={{ background: errors.linkSheets ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={respostas.linkSheets || ""}
            onChange={(e) => handleInputChange("linkSheets", e.target.value)}
            error={errors.linkSheets}
            fullWidth
            InputLabelProps={{
              style: { color: ThemeColors.gray50 }
            }}
          />
          {errors.linkSheets && <FormHelperText style={{ color: "red" }}>{errors.linkSheets}</FormHelperText>}
        </FormControl>
      )}

      {respostas.formatSheets === "API" && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel
            style={{ color: ThemeColors.gray50 }}
          >Selecione a API:</InputLabel>
          <Select
            name="api"
            label="Selecione a API"
            value={respostas.api || ""}
            onChange={(e) => {
              handleInputChange("api", e.target.value);
            }}
            sx={{ background: errors.api ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            required
          >
            {apiData.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
          {!!errors.api && <FormHelperText style={{ color: "red" }}>{errors.api}</FormHelperText>}
        </FormControl>
      )}
      <Button
        variant="contained"
        onClick={() => {
          setLoading(true);
          CheckSheetsPermission();
        }}
        fullWidth
        disabled={loading}
      >
        Carregar dados
      </Button>
    </Grid>
  );
}

function DataDisplayTable({ data, setData, handleSubmit, variablesOfTemplate, page, setPage }) {
  // State to control the form fields
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = useState({});

  // UseEffect para evitar a re-renderização das páginas
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const newInputValues = {};

    currentItems.forEach((item, i) => {
      const absoluteIndex = startIndex + i;
      newInputValues[absoluteIndex] = item.variable || '';
    })
    setValues(newInputValues);
  }, [data, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeSelect = (value, index) => {
    setValues({
      ...values,
      [index]: value
    });

    setData(prevData =>
      prevData.map((dataItem, dataIndex) =>
        dataIndex === index ? { ...dataItem, variable: value } : dataItem
      )
    );
  };

  const verifyIfVariableIsSelected = (variable) => {

    if (variable === 'WhatsApp' || variable === 'Email') {
      return false;
    }
    return data.some(item => item.variable === variable);
  }

  const renderSelects = () => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);

    return currentItems.map((item, index) => {
      const absoluteIndex = startIndex + index;
      return (
        <TableRow key={absoluteIndex}>
          <TableCell>{item.field}</TableCell>
          <TableCell>
            <Select
              labelId={"type_send_id" + index}
              id={"type_send" + index}
              value={values[absoluteIndex] || "Selecione"}
              onChange={(e) => {
                handleChangeSelect(e.target.value, absoluteIndex)
              }} // Passa o index para a função
              fullWidth
              style={{ boxShadow: 'none' }} // Remove o sombreamento do Select
              MenuProps={{
                PaperProps: {
                  style: { boxShadow: 'none', border: '1px solid #d3d3d3' }
                },
              }}
              size="small"
              sx={{ background: '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            >
              <MenuItem value="Selecione">Selecione</MenuItem>
              {Object.values(variablesOfTemplate).flat().map((value) => (
                <MenuItem key={value} value={value} disabled={verifyIfVariableIsSelected(value)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
      <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CAMPO</TableCell>
              <TableCell>REPRESENTA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSelects()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
        Enviar
      </Button>
    </Grid>
  );
}

function PageAddSheets() {

  // State to authenticate control
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  // State to control the form fields
  const [copyRespostas, setCopyRespostas] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [countEmptyVariables, setCountEmptyVariables] = useState(0);
  const [dataR, setDataR] = useState([]);
  const [errors, setErrors] = useState({});
  const [fieldChannelSelected, setFieldChannelSelected] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [verifyEmptyVariables, setVerifyEmptyVariables] = useState(false);
  const [erroLoading, setErroLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [padronizationPage, setPadronizationPage] = useState(0);

  const steps = [
    { desc: "Escolha um nome para identificar a planilha" },
    { desc: "Crie uma descrição para a planilha" },
    { desc: "Indique o formato da planilha" },
    { desc: "Selecione o arquivo ou cole o link da planilha" },
    { desc: 'Clique em "Carregar dados" para visualizar os campos da planilha' },
    { desc: "Para cada campo, selecione o que ele representa" }
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['UUID']) {
      fetchData();
      console.log("Pegando os dados")
    }
  }, [userInfo]);

  const fetchData = async () => {
    try {

      let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
      info = JSON.stringify(info);

      const translations = {
        "Personal_information": "Informações Pessoais",
        "Contact": "Contato",
        "Address": "Endereço",
        "Financial_References": "Referências Financeiras"
      };

      const resultJson = await GetVariables();

      console.log('resultJson', resultJson);

      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1];
        if (!acc[category]) {
          acc[category] = [];
        }

        acc[category].push(item[0]);

        return acc;

      }, {});

      setVariablesOfTemplate(result);

    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };

  function TabPanel(props) {

    const { children, value, index, ...other } = props;
    const [tabValue, setTabValue] = useState(0);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const preSubmit = () => {

    const newErrors = {};
    if (!copyRespostas.nameSheets) newErrors.nameSheets = "Campo obrigatório";
    if (!copyRespostas.descriptionSheets) newErrors.descriptionSheets = "Campo obrigatório";
    if (!copyRespostas.linkSheets && copyRespostas.formatSheets === "Google planilhas") newErrors.linkSheets = "Campo obrigatório";
    if (!copyRespostas.formatSheets) newErrors.formatSheets = "Campo obrigatório";
    if (!copyRespostas.deleteDuplicatedVariables) newErrors.deleteDuplicatedVariables = "Campo obrigatório";

    setErrors(newErrors);

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    const hasEmptyVariable = variables.some((item) => !item.variable);
    const countEmptyVariable = variables.filter((item) => !item.variable).length;

    setVerifyEmptyVariables(hasEmptyVariable);
    setCountEmptyVariables(countEmptyVariable);

    const variablesWhatsApp = variables.filter((item) => item.variable === 'WhatsApp');
    const variablesEmail = variables.filter((item) => item.variable === 'Email');

    if (variablesWhatsApp.length > 0 || variablesEmail.length > 0) {
      setFieldChannelSelected(true);
    } else {
      setFieldChannelSelected(false);
      setOpenModalConfirm(true);
      return false;
    }

    setOpenModalConfirm(true);

  }

  const handleSubmit = async () => {

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    let dados = { ...copyRespostas, variables, 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
    const response = await PostListagens(dados, userInfo['UUID']);

    if (response.status === "OK") {

      navigate('/planilhas');

    } else {

      //alert('Erro ao enviar os dados');

    }

  };

  const handleModalConfirm = () => {
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  useEffect(() => {
    // Simulate a API call
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      < HeaderPage title="Adicionar Planilhas" />

      <Grid container spacing={4} sx={{ padding: 5 }}>
        <Grid item xs={12} lg={6} sx={{ display: "flex", flexDirection: "column" }}>
          <HelpAdd Steps={steps} Title="Importe sua planilha" />
          {
            (copyRespostas.nameSheets === "" || copyRespostas.descriptionSheets === "" || copyRespostas.formatSheets === "" || copyRespostas.linkSheets === "") && (
              <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
            )
          }
          {
            !fieldChannelSelected && dataR.length > 0 && (
              <AlertSnackbar message="WhatsApp ou Email não selecionado" severity="error" open={true} />
            )
          }
          {
            verifyEmptyVariables && (
              <AlertSnackbar message={`Existem ${countEmptyVariables} campos sem seleção`} severity="warning" open={true} />
            )
          }
          {
            erroLoading && (
              <AlertSnackbar message="Lembre-se de compartilhar a planilha com o e-mail:<br>planilhas-n8n@projeto-brio-n8n.iam.gserviceaccount.com" severity="error" open={true} />
            )
          }
        </Grid>
        <Grid item xs={12} lg={6}  >
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Form and Standardization Tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
            <Tab label="Formulário" />
            <Tab label="Padronização" disabled={!copyRespostas.nameSheets || !copyRespostas.descriptionSheets || !copyRespostas.formatSheets || !copyRespostas.linkSheets || erroLoading} />
          </Tabs>
          <TabPanel value={tabValue} index={0} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
            <SheetsForm setDataR={setDataR} copyRespostas={copyRespostas} setCopyRespostas={setCopyRespostas} setErrors={setErrors} errors={errors} setTabValue={setTabValue} setErroLoading={setErroLoading} uuidClient={userInfo['UUID']} />
          </TabPanel>
          <TabPanel value={tabValue} index={1} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
            <DataDisplayTable data={dataR} setData={setDataR} handleSubmit={preSubmit} variablesOfTemplate={variablesOfTemplate} page={padronizationPage} setPage={setPadronizationPage} />
          </TabPanel>
        </Grid>
      </Grid>
      <ModalR
        cancelText={!fieldChannelSelected ? "Voltar" : "Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={!fieldChannelSelected ? "" : "Sim, tenho certeza"}
        subTitle={!fieldChannelSelected ? "Selecione a coluna onde esteja os WhatsApps ou Emails em sua planilha"
          : "Você deseja finalizar a importação da planilha?"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
        title={!fieldChannelSelected ? "Alguns campos são obrigatórios"
          : verifyEmptyVariables ? `Existem ${countEmptyVariables} campos sem seleção` : "Finalizar importação"}
        emoji={verifyEmptyVariables ? "emoji04" : "emoji01"}
        iconColor={ThemeColors.purple400}
      />
    </>
  );
}

export default PageAddSheets;