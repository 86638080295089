// This endpoint is used to create a new request. It is used in the CreateRequest page.
// This endpoint is used in the CreateRequest page.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136773687/RequestRoutes

import axios from 'axios';

export async function RequestCreate(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/create/request`, 
            data,
            { withCredentials: true }
        );
        
        const responseData = response.data;


        if (response.status === 201 || response.status === 200 && responseData.message === 'Request created successfully') {

            responseData.status = 'OK';

        } else {

            responseData.status = 'ERROR';
        
        }

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}