// This page is responsible for the financial management of the user
// Contains a table with the user's charges and a button to generate new charges, add balance and pay bills
// Author: Vitor de Jesus

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-UI imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

// Functions and Components to project
import DownloadingRoundedIcon from '@mui/icons-material/DownloadingRounded';
import DynamicTable from "../../components/Tables/DynamicTable";
import Payments2 from "../../assets/ilustrations/Payments2.png"
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Titles from "../../components/Titles/Titles";
import { Typography } from "@mui/material";

import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import { PaidOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Navigate = ({ links }) => {

    const [hoveredLink, setHoveredLink] = useState(links[0]?.title);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % links.length);
        }, 10000);

        return () => clearInterval(interval);
    }, [links.length]);

    useEffect(() => {
        setHoveredLink(links[currentIndex].title);
    }, [currentIndex, links]);

    return (
        <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '20px' }}>
            <Grid container spacing={3}>
                {links.map((link) => (
                    <Grid item xs={4} key={link.title}>
                        <Box
                            display={"flex"}
                            alignItems="center"
                            textAlign="start"
                            padding="20px"
                            borderRadius="6px"
                            flex="1"
                            backgroundColor={hoveredLink === link.title ? '#DDD4FF' : 'white'}
                            onClick={() => link.onClick(link.link)}
                            onMouseEnter={() => setHoveredLink(link.title)}
                            onMouseLeave={() => setHoveredLink(links[currentIndex].title)}
                            style={{ cursor: 'pointer', transition: 'background-color 0.8s ease-in-out, transform 0.5s ease-in-out' }}
                            sx={{
                                '&:hover': {
                                    transform: 'scale(1.03)',
                                    transition: 'transform 0.5s ease-in-out',
                                }
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    minWidth: "79px",
                                    minHeight: "79px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "6px",
                                    background: hoveredLink === link.title ? "#8033F4" : "#DDD4FF",
                                    marginRight: "15px",
                                    transition: 'background 0.8s ease-in-out, opacity 0.5s ease-in-out',
                                    opacity: hoveredLink === link.title ? 1 : 0.8,
                                }}
                            >
                                <StatusIcon
                                    status={link.icon}
                                    color={hoveredLink === link.title ? "white" : "#8033F4"}
                                    height={link.heightIcon || "30px"}
                                    width={link.heightIcon || "30px"}
                                />
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center" maxWidth="calc(100% - 89px)">
                                <Titles
                                    type={"H4"}
                                    font={"medium"}
                                    color={hoveredLink === link.title ? "#300D68" : "#8033F4"}
                                    children={link.title}
                                />
                                <Typography
                                    variant="body1"
                                    style={{ color: "#300D68" }}
                                >
                                    {link.text}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
dayjs.extend(utc);

function Financial() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [charges, setCharges] = useState([]);
    const [showBalance, setShowBalance] = useState(false);
    const [balance_current, setBalanceCurrent] = useState(0);

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['EMAIL']) {
            fetchData();
        }
    }, [userInfo]);
    function convertToISO(dateString) {
        return new Date(dateString).toISOString();
    }

    const fetchData = async () => {
        try {

            const token = localStorage.getItem('jwt_token');

            const { data: chargeData } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/listar/faturas`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            console.log('chargeData', chargeData);

            let mapData = chargeData.map((item) => {

                console.log('data antes de converter', item.data_vencimento);

                let isoDateString = convertToISO(item.data_vencimento);
                let data_vencimento = dayjs.utc(isoDateString);
                console.log('data depois de converter', data_vencimento);

                let formatData = data_vencimento.format('DD/MM/YYYY');
                console.log('data formatada', formatData);

                let statusColor = item.status_fatura === 'WAIT' ? '#1E22AA'
                    : item.status_fatura === 'ATIVA' ? ThemeColors.yellow03
                        : item.status_fatura === 'RECEBIDO' ? ThemeColors.green02
                                : item.descricao_grupo === 'Taxa administrativa' ? ThemeColors.red02
                                    : ThemeColors.gray50;



                return {
                    'icon': 'PaidOutlined',                     // Ícone padrão
                    'Nome': item.nome_devedor,                  // Nome do devedor
                    'CPF/CNPJ': item.cpf_cnpj,                  // CPF ou CNPJ do devedor
                    'Valor Original': (item.valor_original !== null && item.valor_original !== '') ? item.valor_original + `@${statusColor}` : 0 + `@${statusColor}`, // Valor original
                    'Data de Fatura': item.data_fatura,         // Data da fatura
                    'Data de Vencimento': formatData,            // Data de vencimento formatada
                    'Status': item.status_fatura === 'WAIT' ? 'Processando' : item.descricao_grupo === 'Taxa administrativa' ? 'Taxa' : item.status_fatura,
                    'Status do Processo': item.status_processo === 'WAIT'
                        ? 'Processando'
                        : item.status_processo === 'FINISH'
                            ? 'Processada'
                            : 'Desconhecido',
                    'Pix Gerado': item.pix_gerado,              // Status se o PIX foi gerado
                    'desc': item.descricao_grupo,
                    'Crédito Gerado': item.credito_gerado,      // Status se o crédito foi gerado
                    'Boleto Gerado': item.boleto_gerado,        // Status se o boleto foi gerado
                    'TXID': item.txid,                            // ID da transação
                    'tipo': item.tipo,                          // Tipo da cobrança
                };
            });

            console.log('mapData', mapData);



            const { data: balanceData } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/consultar/saldo`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setBalanceCurrent(parseFloat(balanceData.saldo).toFixed(2)); // Formatar para duas casas decimais
            setCharges(mapData);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const handlePage = (link) => {
        navigate(link ? link : '/financeiro/adicionar-recarga');
    };
    const formartValueBalance = (value) => {
        // Convert to a number if it's a string
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

        // Verifique se a conversão resultou em um número válido
        if (isNaN(numericValue)) {
            return "R$ 0,00"; // Ou outra string de fallback se o valor não for um número
        }

        // Format the value to Brazilian currency format
        const formattedValue = Math.abs(numericValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        console.log('formattedValue', formattedValue);
        // Return the formatted value
        return formattedValue;
    };


    if (loading) {
        return <Loading open={true} />
    }

    const links = [
        { title: "Cobranças", text: "Gere cobranças para seus clientes e receba pagamentos de forma rápida e segura.", icon: "Cobrança", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: handlePage, pago: true, 'link': '/financeiro/gerar-cobranças' },
        { title: "Depósitos", text: "Adicione saldo para realizar transferências e pagamentos de forma rápida e segura.", icon: "AddCard", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: handlePage, pago: false, 'link': '/financeiro/adicionar-recarga' },
        { title: "Pagamentos", text: "Utilize o saldo disponível para pagar suas contas e boletos de forma rápida e segura.", icon: "Pix", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: handlePage, pago: true, 'link': '/financeiro/enviar-pix' },
    ];

    const activeLink = "Recargas";



    return (
        <Fragment>
            <Fade in={!loading} timeout={1000}>
                <Box width={"100%"} height={"100vh"}>
                    <Navigate links={links} activeLink={activeLink} navigate={navigate} />
                    {
                        charges && charges.length > 0 && (
                            <>
                                {showBalance ? (
                                    <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '30px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 30, color: ThemeColors.gray50, cursor: 'pointer' }} onClick={() => setShowBalance(false)}>
                                                Saldo Atual:
                                                <span style={{ color: '#8033F4' }}> {formartValueBalance(balance_current)}</span>
                                            </Typography>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '30px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}> {/* Mudei para apenas 'alignItems: center' */}
                                            <Typography style={{ fontSize: 30, color: ThemeColors.gray50 }} >
                                                Saldo Atual:
                                            </Typography>
                                            <VisibilityOffIcon style={{ cursor: 'pointer', color: ThemeColors.purple900, fontSize: 30, marginLeft: 10 }} onClick={() => setShowBalance(true)} />
                                        </div>
                                    </div>
                                )}
                                <DynamicTable
                                    title="Charges"
                                    data={charges}
                                    columns={[
                                        { header: '', field: 'icon', render: (icon) => <PaidOutlined /> },
                                        { header: 'Nome', field: 'Nome' },
                                        { header: 'Descrição', field: 'desc' },
                                        { header: 'Data emissão', field: 'Data de Vencimento' },
                                        {
                                            header: 'Valor',
                                            field: 'Valor Original',
                                            render: (item) => {
                                                // Separando o valor e a cor
                                                console.log('item', item); // Verificando a estrutura de item
                                                const [value, color] = item.split('@'); // Separar o valor da cor

                                                // Converter o valor para número
                                                const numericValue = parseFloat(value); // Garantir que seja um número
                                                const formattedValue = formartValueBalance(numericValue); // Formatar o valor

                                                const sign = numericValue < 0 ? '-' : '+'; // Lógica para determinar o sinal

                                                console.log('color', color); // Verificando o valor da cor

                                                return (
                                                    <span style={{ color }}> {/* Usando a cor mapeada */}
                                                        {sign} {formattedValue} {/* Adicionando o sinal antes do valor */}
                                                    </span>
                                                );
                                            }
                                        }



                                        ,
                                        { header: 'Status', field: 'Status', render: (status) => <StatusLabel status={status} usingOnTable /> },
                                    ]}
                                    actions={[

                                    ]}
                                    button={{ icon: <DownloadingRoundedIcon style={{ color: '#fff' }} />, tooltip: 'Baixar relatório', action: () => console.log('Download') }}

                                    activeSearch={true}
                                    titleSearch={'Pesquisar Charge'}
                                    titleLabel={'Charges por página'}
                                />
                            </>
                        )
                        || (
                            <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "80%" }}>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <img src={Payments2} alt="Você não tem saldo" width={"300px"} />
                                    <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center", alignItems: "center" }}>
                                        <Titles bold type={"H3"} children={"Parece que você não tem movimentações financeiras."} color={"#8033F4"} />
                                        <Titles type={"P1"} children={"Adicione saldo para automatizar seus pagamentos e assinaturas."} />
                                        <Button
                                            variant='contained'
                                            style={{ color: '#fff', width: '80%', padding: '10px', marginTop: "10px" }}
                                            onClick={() => handlePage()}
                                        >
                                            Adicionar saldo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Fade>

        </Fragment>
    );
}

export default Financial;
