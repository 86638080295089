
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TimePicker } from '@mui/x-date-pickers';

import { createTheme, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import HelpAdd from '../../components/Cards/HelpAdd';
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";

import ThemeColors from '../../components/ThemeColors/ThemeColors';
import themeColors from '../../components/ThemeColors/ThemeColors';
import Loading from '../../components/Loading/Loading';
import LockIcon from '@mui/icons-material/Lock';
import { CampaignSave } from '../../api/backend/Campaigns/CampaignSave';
import axios from 'axios';
import { GetAllTemplates } from '../../api/backend/Templates/GetAllTemplates';

import { GetListagens } from "../../api/backend/Listagens/GetListagens";
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { Channels } from '../../api/backend/Channels/Channels';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function PageAddPersonalized() {

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [minSendTime, setMinSendTime] = useState(null)
    const [maxSendTime, setMaxSendTime] = useState(null);
    const [whatsAppChannel, setWhatsAppChannel] = useState([]);
    const [selectedWhatsAppChannel, setSelectedWhatsAppChannel] = useState('');
    const [whatsAppModel, setWhatsAppModel] = useState('');
    const [smsChannel, setSmsChannel] = useState([]);
    const [selectedSmsChannel, setSelectedSmsChannel] = useState('');
    const [smsModel, setSmsModel] = useState('');
    const [emailChannel, setEmailChannel] = useState([]);
    const [selectedEmailChannel, setSelectedEmailChannel] = useState('');
    const [emailModel, setEmailModel] = useState('');
    const [listSelected, setListSelected] = useState('');
    const [dateDynamic, setDateDynamic] = useState('');
    const [dateColumn, setDateColumn] = useState('');
    const [weekDays, setWeekDays] = useState();
    const [sendReference, setSendReference] = useState('');
    const [daysBeforeAfter, setDaysBeforeAfter] = useState('');
    const [sendRule, setSendRule] = useState('');
    const [column, setColumn] = useState('');
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState('');
    const [fieldChannelSelected, setFieldChannelSelected] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [type, setType] = useState('');
    const { authenticated, userType, uuidClient } = isAuthenticated();
    // Loading Transition state
    const [showLoading, setShowLoading] = useState(true);

    const navigate = useNavigate();


    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };


    const steps = [
        {
            'desc': 'Escolha um título para sua campanha'
        },
        {
            'desc': 'Escolha uma descrição para sua campanha'
        },
        {
            'desc': 'Defina a data de início e fim da campanha'
        },
        {
            'desc': 'Defina o horário de inicio e limite para envio'
        },
        {
            'desc': 'Escolha cada canal que deseja utilizar juntamente com o modelo'
        },
        {
            'desc': 'Mude para a aba Planilha para definir a listagem e regras de envio'
        }
    ]

    const steps2 = [
        {
            'desc': 'Escolha a listagem que deseja utilizar'
        },
        {
            'desc': 'Se a sua planilha inclui várias datas, como datas de vencimento, ative a opção "Datas Dinâmicas" e selecione a coluna que contém as datas.'
        },
        {
            'desc': 'Se deseja enviar somente em dias úteis, ative a opção "Enviar somente em dias úteis"'
        },
        {
            'desc': 'Escolha a referência de envio, se antes, depois ou no mesmo dia da data'
        },
        {
            'desc': 'Defina quantos dias antes ou depois da data deseja enviar'
        },
        {
            'desc': 'Se deseja adicionar uma regra de envio, ative a opção "Adicionar Regra de Envio"'
        },
        {
            'desc': 'Escolha a coluna, o operador e o valor da regra'
        },
        {
            'desc': 'Clique em Salvar para finalizar a criação da campanha'
        }
    ]

    const [errors, setErrors] = useState([]);
    const [listagens, setListagens] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [columns, setColumns] = useState([]);
    const [modelsAvailable, setModelsAvailable] = useState([]);

    useEffect(() => {
            fetchData();
            fetchData2();
            fetchData3();
    }, [userInfo])

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    const fetchData = async () => {
        try {

            const resultJson = await GetListagens();

            console.log(resultJson);


            const listagens = resultJson.map((listagem) => {
                return {
                    id: listagem[0],
                    icon: listagem[7],
                    title: listagem[1],
                    desc: listagem[2],
                    type: listagem[7],
                    link: listagem[5],
                };
            });

            console.log(listagens);

            setListagens(listagens);


        } catch (error) {
            console.error("Erro ao obter dados da API:", error);
        }
    };



    const fetchData3 = async () => {
        try {
           
            const resultJson = await Channels();

            console.log('channels:', resultJson);
            // Filtra e mapeia os canais WhatsApp disponíveis
            const whatsAppChannels = resultJson.status
                .filter(item => item.integration=== 'WhatsApp' || item.integration=== 'WhatsApp WEB')
                .map(item => ({
                    id: item.id,
                    title: item.owner
                }));

            whatsAppChannels.unshift({ id: false, title: 'Não enviar' });
            setWhatsAppChannel(whatsAppChannels);

            const smsChannels = resultJson.status
                .filter(item => item.integration_system === 'sms')
                .map(item => ({
                    id: item.id,
                    title: item.instance
                }));

            smsChannels.unshift({ id: false, title: 'Não enviar' });
            setSmsChannel(smsChannels);

            const emailChannels = resultJson.status
                .filter(item => item.integration=== 'E-mail')
                .map(item => ({
                    id: item.id,
                    title: item.owner
                }));

            emailChannels.unshift({ id: false, title: 'Não enviar' });
            setEmailChannel(emailChannels);

            console.log('channels:', whatsAppChannels, smsChannels, emailChannels);

        } catch (error) {
            console.error('Erro ao obter status da instância:', error);
        }
    };


    const getColumns = async () => {

        const response = await axios.post('https://api.123zap.com.br/webhook/get-variables', {
            uuid_account: userInfo['UUID'],
            full_token: userInfo['TOKEN'],
            listagem: listagens.find(listagem => listagem.id === listSelected).link,
            id: userInfo['EMAIL'],
            listSelected: listSelected
        });

        console.log(response.data.data);

        const items = response.data.data.map(item => {
            return {
                id: item.id,
                name: item.name,
                column: item.column,
            }
        });

        setColumns(items);
    }

    useEffect(() => {
        if (listSelected !== '') {
            getColumns();
        }
    }, [listSelected])

    const startDateTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('startDate') ? '#FFEBEE' : '#F9F9F9',
                        color: themeColors.gray30,
                        borderRadius: '12px',
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.purple400,
                        borderRadius: '2px',
                        borderWidth: '1px',
                        border: '1px solid',
                        backgroundColor: '#FFF',
                        '&:hover': {
                            backgroundColor: ThemeColors.purple400,
                            color: '#FFF',
                        },
                        '&.Mui-selected': {
                            backgroundColor: ThemeColors.purple400 + " !important",
                            color: '#FFF !important',
                        },
                    }
                }

            }

        }
    });

    const endDateTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('endDate') ? '#FFEBEE' : '#F9F9F9',
                        borderRadius: '12px',
                        color: themeColors.gray30,
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.purple400,
                        borderRadius: '2px',
                        borderWidth: '1px',
                        border: '1px solid',
                        backgroundColor: '#FFF',
                        '&:hover': {
                            backgroundColor: ThemeColors.purple400,
                            color: '#FFF',
                        },
                        '&.Mui-selected': {
                            backgroundColor: ThemeColors.purple400 + " !important",
                            color: '#FFF !important',
                        },
                    }
                }

            }

        }
    });

    const startTimeTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: errors.includes('minSendTime') ? '#FFEBEE' : '#F9F9F9',
                        borderRadius: '12px',
                        color: themeColors.gray30,
                    },
                },
            },
            MuiPickersClock: {
                styleOverrides: {
                    clock: {
                        backgroundColor: '#FFF',
                        borderRadius: '12px',
                        border: '1px solid #DBDBDB',
                        padding: '10px',
                    },
                    pin: {
                        backgroundColor: ThemeColors.purple400,
                    }
                }
            }
        }
    });

    useEffect(() => {
        //emailModel
        console.log('emailModel', emailModel);
    }, [emailModel])


    const verifyTabFormNext = (tabIndex) => {

        let errors = [];

        if (tabIndex === 0) {

            if (title === '') {
                errors.push('title');
            }
            if (desc === '') {
                errors.push('desc');
            }
            if (startDate === null) {
                errors.push('startDate');
            }
            if (endDate === null) {
                errors.push('endDate');
            }
            if (minSendTime === null) {
                errors.push('minSendTime');
            }
            if (selectedWhatsAppChannel === '') {
                errors.push('whatsAppChannel');
            }
            if (selectedSmsChannel === '') {
                errors.push('smsChannel');
            }
            if (selectedEmailChannel === '') {
                errors.push('emailChannel');
            }

            if (selectedWhatsAppChannel != '' && whatsAppModel === '') {
                errors.push('whatsAppModel');
            }

            if (selectedSmsChannel != '' && smsModel === '') {
                errors.push('smsModel');
            }

            if (selectedEmailChannel != '' && emailModel === '') {
                errors.push('emailModel');
            }
        }

        if (tabIndex === 1) {
            if (listSelected === '') {
                errors.push('listSelected');
            }
            if (type === '') {
                errors.push('type');
            }
            if (dateDynamic === '') {
                errors.push('dateDynamic');
            }
            if (dateColumn === '') {
                errors.push('dateColumn');
            }
            if (weekDays === '') {
                errors.push('weekDays');
            }
            if (sendReference === '') {
                errors.push('sendReference');
            }
            if (daysBeforeAfter === '') {
                errors.push('daysBeforeAfter');
            }
            if (sendRule === '') {
                errors.push('sendRule');
            }
            if ((column === '' || operator === '' || value === '')) {
                errors.push('column');
                errors.push('operator');
                errors.push('value');

            }

        }

        setErrors(errors);

        return errors.length === 0;

    }

    const nextTab = (tabIndex) => {
        if (verifyTabFormNext(tabIndex)) {
            setTabIndex(tabIndex + 1);
        }
    }

    const previousTab = (tabIndex) => {
        if (verifyTabFormNext(tabIndex)) {
            setTabIndex(tabIndex - 1);
        }
    }

    useEffect(() => {
        // Simulate a API call
        setTimeout(() => setShowLoading(false), 1000);
    }, []);


    const saveCampaign = async () => {

        console.log('listagens:', listagens);   
        console.log('listSelected:', listSelected);
        let data = {
            title: title ? title : null,
            desc: desc ? desc : null,
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            minSendTime: minSendTime ? minSendTime : null,
            maxSendTime: maxSendTime ? maxSendTime : null,
            whatsapp_id: selectedWhatsAppChannel ? selectedWhatsAppChannel : null,
            whatsAppModel: whatsAppModel ? whatsAppModel : null,
            sms_id: selectedSmsChannel ? selectedSmsChannel : null,
            smsModel: smsModel ? smsModel : null,
            email_id: selectedEmailChannel ? selectedEmailChannel : null,
            emailModel: emailModel ? emailModel : null,
            listSelected: listSelected ? listSelected : null,
            type: type ? type : null,
            dateDynamic: dateDynamic ? true : false,
            dateColumn: dateColumn ? dateColumn : null,
            weekDays: weekDays ? weekDays : false,
            sendReference: sendReference ? sendReference : null,
            daysBeforeAfter: daysBeforeAfter ? daysBeforeAfter : null,
            sendRule: sendRule ? sendRule : false,
            column: column ? column : null,
            operator: operator ? operator : null,
            value: value ? value : null,
            uuid_account: userInfo['UUID'],
            list_type: listagens.find(listagem => listagem.id === listSelected).type,
        }

        const response = await CampaignSave(data);

        console.log(response, 'response');

        if (response.status === 200) {
            navigate('/campanhas');
        }


    }


    const fetchData2 = async () => {
        try {

           
            const resultJson = await GetAllTemplates();

            console.log('modelos encontrados:', resultJson);

            const items = resultJson.map(item => {
                return {
                    id: item.id,
                    title: item.title,
                    uuidAccount: item.uuid_account,
                    integration_icons:  item.channel_type,
                    status: item.status,
                    msg_type:   item.msg_type,
                    channel_type: item.channel_type,
                };

            }).filter(item => item !== null);

            console.log('models:', items)

            setModelsAvailable(items);
        } catch (error) {
            console.error('Erro ao obter modelos:', error);
        } finally {
        }
    };



    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }



    return (
        <>
            <HeaderPage title="Campanhas" />
            <Grid container spacing={4} sx={{ padding: 5 }}>
                <Grid item xs={12} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <HelpAdd Steps={tabIndex === 0 ? steps : steps2} Title="Crie uma nova campanha2" />
                    {
                        errors.length > 0 && <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
                    }
                    {
                        fieldChannelSelected === 'ERROR' &&
                        <AlertSnackbar message="É necessário selecionar um canal para enviar a campanha ( WhatsApp, SMS ou Email )" severity="error" open={true} />
                    }
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
                        <Tab label="Informações Gerais" disabled={false} />
                        <Tab label="Configurações" disabled={false} />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        placeholder='Título da campanha'
                                        id="Titulo"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => setTitle(e.target.value)}
                                        sx={{ background: errors.includes('title') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                        autoComplete='off'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        placeholder="Descrição da campanha"
                                        id="Descrição"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => setDesc(e.target.value)}
                                        sx={{ background: errors.includes('desc') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <ThemeProvider theme={startDateTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                            <DatePicker
                                                label="Qual a data de início?"
                                                inputFormat="dd/MM/yyyy" // Corrigido o formato
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                minDate={new Date()} // Define a data mínima como hoje
                                                InputLabelProps={{ shrink: true }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <ThemeProvider theme={endDateTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                label="Qual a data de término?"
                                                renderInput={(params) => <TextField {...params} />}
                                                value={endDate}
                                                minDate={new Date()} // Define a data mínima como hoje
                                                onChange={(newValue) => setEndDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl style={{ marginBottom: '15px' }} fullWidth>
                                    <ThemeProvider theme={startTimeTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                            <TimePicker
                                                label="Qual o horário de início?"
                                                ampm={false}
                                                minutesStep={60} // Define os minutos apenas em intervalos de 60
                                                renderInput={(params) => <TextField {...params} />}
                                                value={minSendTime}
                                                onChange={(newValue) => setMinSendTime(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '25px' }}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="WhatsApp" style={{ color: themeColors.gray30 }}>Deseja enviar pelo WhatsApp?</InputLabel>
                                    <Select
                                        labelId="WhatsApp"
                                        id="WhatsApp"
                                        label="WhatsApp"
                                        fullWidth
                                        onChange={(e) => setSelectedWhatsAppChannel(e.target.value)}
                                        value={selectedWhatsAppChannel}
                                        style={{ 'background': errors.includes('whatsAppChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            whatsAppChannel.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {whatsAppChannel && selectedWhatsAppChannel !== false ? (
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="modelo-whatsapp" style={{ color: themeColors.gray30 }}>Qual modelo vai usar no WhatsApp?</InputLabel>
                                        <Select
                                            labelId="modelo-whatsapp"
                                            id="modelo-whatsapp"
                                            value={whatsAppModel}
                                            label="Modelo"
                                            fullWidth
                                            onChange={(e) => setWhatsAppModel(e.target.value)}
                                            style={{ 'background': errors.includes('whatsAppModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                        >
                                            {
                                                // Exibe somente os modelos disponíveis para o canal WhatsApp
                                                modelsAvailable.filter(model => model.channel_type === 'WhatsApp').map((model) => {
                                                    return (
                                                        <MenuItem value={model.id}>{model.title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <TextField
                                            label="Modelo (Bloqueado)"
                                            id="Modelo"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                            autoComplete='off'
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="SMS" style={{ color: themeColors.gray30 }}>Deseja enviar pelo SMS?</InputLabel>
                                    <Select
                                        labelId="SMS"
                                        id="SMS"
                                        onChange={(e) => setSelectedSmsChannel(e.target.value)}
                                        label="SMS"
                                        fullWidth
                                        value={selectedSmsChannel}
                                        style={{ 'background': errors.includes('smsChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            smsChannel?.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                smsChannel && selectedSmsChannel !== false ? (

                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="modelo-sms" style={{ color: themeColors.gray30 }}>Qual modelo vai usar no SMS?</InputLabel>
                                            <Select
                                                labelId="modelo-sms"
                                                id="modelo-sms"
                                                label="Modelo"
                                                fullWidth
                                                onChange={(e) => setSmsModel(e.target.value)}
                                                style={{ 'background': errors.includes('smsModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                            >
                                                {
                                                    // Exibe somente os modelos disponíveis para o canal SMS
                                                    modelsAvailable.filter(model => model.channel_type === 'SMS').map((model) => {
                                                        return (
                                                            <MenuItem value={model.id}>{model.title}</MenuItem>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="Modelo (Bloqueado)"
                                                id="Modelo"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                autoComplete='off'
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>)
                            }

                            < Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Email" style={{ color: themeColors.gray30 }}>Deseja enviar pelo Email?</InputLabel>
                                    <Select
                                        labelId="Email"
                                        id="Email"
                                        onChange={(e) => setSelectedEmailChannel(e.target.value)}
                                        label="Email"
                                        value={selectedEmailChannel}
                                        fullWidth
                                        style={{ 'background': errors.includes('emailChannel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            emailChannel.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                emailChannel && selectedEmailChannel !== false ? (

                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="modelo-email">Qual modelo vai usar no Email?</InputLabel>
                                            <Select
                                                labelId="modelo-email"
                                                id="modelo-email"
                                                value={emailModel}
                                                onChange={(e) => setEmailModel(e.target.value)}
                                                label="Modelo"
                                                fullWidth
                                                style={{ 'background': errors.includes('emailModel') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                            >
                                                {
                                                    // Exibe somente os modelos disponíveis para o canal Email
                                                    modelsAvailable.filter(model => model.channel_type === 'E-mail').map((model) => {
                                                        return (
                                                            <MenuItem value={model.id}>{model.title}</MenuItem>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                ) : (
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                label="Modelo (Bloqueado)"
                                                id="Modelo"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                autoComplete='off'
                                                disabled={true}
                                                InputProps={{
                                                    endAdornment: (
                                                        <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>)
                            }
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="primary" onClick={() => nextTab(tabIndex)} style={{ padding: '10px' }}
                                    fullWidth>Próximo</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>

                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Listagem">Listagem</InputLabel>
                                    <Select
                                        labelId="Listagem"
                                        id="Listagem"
                                        value={listSelected}
                                        onChange={(e) => setListSelected(e.target.value)}
                                        label="Listagem"
                                        fullWidth
                                        style={{ 'background': errors.includes('listSelected') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        {
                                            listagens.map((listagem) => {
                                                return (
                                                    <MenuItem value={listagem.id}>{(listagem.title + ': ' + listagem.desc).substring(0, 30) + '...'
                                                    }</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>

                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '10px' }}>


                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="Type">Tipo de Envio</InputLabel>
                                    <Select
                                        labelId="Type"
                                        id="Type"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        label="type"
                                        fullWidth
                                        style={{ 'background': errors.includes('type') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        <MenuItem value={'Atrasos'}>Atrasos</MenuItem>
                                        <MenuItem value={'Avisos'}>Avisos</MenuItem>
                                        <MenuItem value={'Faturas'}>Faturas</MenuItem>
                                        <MenuItem value={'Inadimplências'}>Inadimplências</MenuItem>
                                        <MenuItem value={'Marketing'}>Marketing</MenuItem>
                                        <MenuItem value={'Outros'}>Outros</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="EnvioDinamico">Datas de envio Dinamicas?</InputLabel>
                                    <Select
                                        labelId="Canal"
                                        id="Envio_Dinamico"
                                        value={dateDynamic}
                                        onChange={(e) => setDateDynamic(e.target.value)}
                                        label="Canal"
                                        fullWidth
                                        style={{ 'background': errors.includes('dateDynamic') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}

                                    >
                                        <MenuItem value={true}>Sim</MenuItem>
                                        <MenuItem value={false}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {
                                dateDynamic ? (
                                    <>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="Column">Em qual coluna estão as datas?</InputLabel>
                                                <Select
                                                    labelId="Column"
                                                    id="Column_select"
                                                    value={dateColumn}
                                                    onChange={(e) => setDateColumn(e.target.value)}
                                                    label="Canal"
                                                    fullWidth
                                                    style={{ background: errors.includes('dateColumn') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                >
                                                    {

                                                        columns.map((column) => {
                                                            return (
                                                                <MenuItem value={column.id}>{column.column}</MenuItem>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="weekDays">Enviar Somente em Dias Úteis?</InputLabel>
                                                <Select
                                                    labelId="weekDays"
                                                    id="weekDays_select"
                                                    value={weekDays}
                                                    onChange={(e) => setWeekDays(e.target.value)}
                                                    label="Canal"
                                                    fullWidth
                                                    style={{ background: errors.includes('weekDays') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                >
                                                    <MenuItem value={true}>Sim</MenuItem>
                                                    <MenuItem value={false}>Não</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="sendReference">Qual será a referência de envio?</InputLabel>
                                                <Select
                                                    labelId="sendReference"
                                                    id="sendReference_select"
                                                    value={sendReference}
                                                    onChange={(e) => setSendReference(e.target.value)}
                                                    label="Canal"
                                                    fullWidth
                                                    style={{ background: errors.includes('sendReference') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                >
                                                    <MenuItem value={'before'}>Antes da data</MenuItem>
                                                    <MenuItem value={'after'}>Depois da data</MenuItem>
                                                    <MenuItem value={'sameDay'}>No mesmo dia</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    placeholder='Quantos dias antes ou depois?'
                                                    id="QuantDias"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: errors.includes('daysBeforeAfter') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                    onChange={(e) => setDaysBeforeAfter(e.target.value)}
                                                    value={daysBeforeAfter}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Não há datas dinâmicas"
                                                    id="Coluna_bloqueada_1"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Sem regras de dias úteis"
                                                    id="Coluna_bloqueada_2"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Data de ínicio fixada anteriormente"
                                                    id="Coluna_bloqueada_3"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Sem necessidade de referência/dias"
                                                    id="Coluna_bloqueada_3"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <FormControl variant='outlined' fullWidth>
                                    <InputLabel id="sendRule">Deseja adicionar regra de envio?</InputLabel>
                                    <Select
                                        labelId="sendRule"
                                        id="sendRule_select"
                                        value={sendRule}
                                        onChange={(e) => setSendRule(e.target.value)}
                                        label="Canal"
                                        fullWidth
                                        style={{ 'background': errors.includes('sendRule') ? '#FFEBEE' : '#F9F9F9', 'borderRadius': '12px' }}
                                    >
                                        <MenuItem value={true}>Sim</MenuItem>
                                        <MenuItem value={false}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            {
                                sendRule ? (
                                    <>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="column">Coluna</InputLabel>
                                                <Select
                                                    labelId="column"
                                                    id="column_select"
                                                    value={column}
                                                    onChange={(e) => setColumn(e.target.value)}
                                                    label="Canal"
                                                    fullWidth
                                                    style={{ background: errors.includes('column') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                >
                                                    {
                                                        columns.map((column) => {
                                                            return (
                                                                <MenuItem value={column.id}>{column.column}</MenuItem>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="operator">Operador</InputLabel>
                                                <Select
                                                    labelId="operator"
                                                    id="operator_select"
                                                    value={operator}
                                                    onChange={(e) => setOperator(e.target.value)}
                                                    label="Canal"
                                                    fullWidth
                                                    style={{ background: errors.includes('operator') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                >

                                                    <MenuItem value={'=='}>Igual</MenuItem>
                                                    <MenuItem value={'!='}>Diferente</MenuItem>
                                                    <MenuItem value={'is null'}>Nulo</MenuItem>
                                                    <MenuItem value={'is not null'}>Não Nulo</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Grid>


                                        {

                                            operator !== 'is null' && operator !== 'is not null' && (
                                                <Grid item xs={4}>
                                                    <FormControl variant="outlined" fullWidth>
                                                        <TextField
                                                            label="Valor"
                                                            id="Valor"
                                                            variant="outlined"
                                                            fullWidth
                                                            style={{ background: errors.includes('value') ? '#FFEBEE' : '#F9F9F9', borderRadius: '12px' }}
                                                            autoComplete="off"
                                                            onChange={(e) => setValue(e.target.value)}
                                                            value={value}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Sem regra de envio"
                                                    id="Coluna_bloqueada_1"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Sem regra de envio"
                                                    id="Coluna_bloqueada_2"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    label="Sem regra de envio"
                                                    id="Coluna_bloqueada_3"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{ background: '#F9F9F9', borderRadius: '12px' }}
                                                    autoComplete="off"
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <LockIcon style={{ color: ThemeColors.gray30 }} />
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            }

                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="primary" fullWidth onClick={() => previousTab(tabIndex)} style={{ padding: '10px' }}>Anterior</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" fullWidth onClick={saveCampaign} style={{ padding: '10px' }}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid >
            </Grid >
        </>
    );


}

export default PageAddPersonalized;